import React from 'react';
import { ProjectData } from 'Components/Project/Project';
import { DepartmentData } from 'Components/Department/Department';
import { ActionData } from 'Components/Action/Action';

export interface Bilingual<T> {
    ru: T;
    en: T;
}

export type Converter<T> = (data: any) => T;

const load = async (path: string, lang: 'ru' | 'en'): Promise<any> => {
    return await fetch(`https://akademia.art/wordpress/wp-json/wp/v2/${path}/?lang=${lang}&per_page=100`, {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'GET',
    }).then((response) => response.json());
};

export const loadData = async <T>(path: string, converter: Converter<T>): Promise<Bilingual<T>> => {
    const [ruRaw, enRaw] = await Promise.all([load(path, 'ru'), load(path, 'en')]);

    const ru = converter(ruRaw);
    const en = converter(enRaw);

    return {
        ru,
        en,
    };
};

const textAreaToArr = (data: any): string[] => {
    return ((data as string) ?? '')
        .replace('\r', '')
        .split('\n')
        .filter((e) => e.trim() !== '');
};

const projectsConverter: Converter<ProjectData[]> = (data) => {
    const dataArr = data as any[];

    return dataArr.map<ProjectData>((entry) => ({
        tag: entry.acf.tag,
        imgUrl: entry.acf.image.url,
        smallImgUrl: entry.acf.image.sizes.medium_large,
        title: entry.acf.title,
        description: entry.acf.description,
        year: entry.acf.year,
        url: entry.acf.behance_link,
    }));
};

const teamConverter: Converter<DepartmentData[]> = (data) => {
    const dataArr = data as any[];

    return dataArr.map<DepartmentData>((entry) => {
        const jobs = textAreaToArr(entry.acf.jobs);
        return {
            title: entry.acf.title,
            employees: textAreaToArr(entry.acf.employees),
            jobs: jobs.length ? jobs : undefined,
        };
    });
};

const weDoConverter: Converter<ActionData[]> = (data) => {
    const dataArr = data as any[];

    return dataArr.map<ActionData>((entry) => {
        return {
            title: entry.acf.title,
            actions: textAreaToArr(entry.acf.actions),
        };
    });
};

const loadAdminEmail = async (): Promise<string> => {
    return await fetch(`https://akademia.art/wordpress/?contact_email=true`, {
        method: 'GET',
    }).then((response) => response.text());
};

// Public api

export interface SiteData {
    projects: Bilingual<ProjectData[]>;
    team: Bilingual<DepartmentData[]>;
    weDo: Bilingual<ActionData[]>;
    adminEmail: string;
}

export const loadSiteData = async (): Promise<SiteData> => {
    const [projects, team, weDo, adminEmail] = await Promise.all([
        loadData('projects', projectsConverter),
        loadData('team', teamConverter),
        loadData('we-do', weDoConverter),
        loadAdminEmail(),
    ]);

    return {
        projects,
        team,
        weDo,
        adminEmail,
    };
};

export const SiteDataContext = React.createContext<SiteData | undefined>(undefined);
