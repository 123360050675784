import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import './styles/fonts.scss';

import reportWebVitals from './reportWebVitals';

import Preloader, { PreloaderTrigger } from 'Preloader';

import { loadSiteData, SiteDataContext } from 'data';

const App = React.lazy(async () => {
    const [moduleExports, siteData] = await Promise.all([
        import('./AppWrapper'),
        loadSiteData(),
        new Promise((resolve) => setTimeout(resolve, 300)),
    ]);

    const AppComponent = moduleExports.default;

    const AppWrapper = () => {
        return (
            <SiteDataContext.Provider value={siteData}>
                <AppComponent />
            </SiteDataContext.Provider>
        );
    };
    return { default: AppWrapper };
});

ReactDOM.render(
    <>
        {/* <React.StrictMode> */}
        <Suspense fallback={<PreloaderTrigger />}>
            <App />
        </Suspense>
        <Preloader />
        {/* </React.StrictMode> */}
    </>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
