import React from 'react';

import './Preloader.scss';

interface PreloaderProps {
    open?: boolean;
}

const Preloader: React.FunctionComponent<PreloaderProps> = ({ open }) => {
    return (
        <div className={open ? 'Preloader open' : 'Preloader'}>
            <video autoPlay loop muted playsInline>
                <source src={process.env.PUBLIC_URL + `/video/loader.webm`} type="video/webm" />
            </video>
        </div>
    );
};

export const PreloaderTrigger = () => {
    return <div className="Preloader-trigger" />;
};

export default Preloader;
